export const PaymentFetch = {
  data() {
    return {};
  },
  methods: {
    async fetchPages(target) {
      await this.$store.dispatch(`transactionEF/${target}`);
    },
    async fetchContent(target, payload) {
      await this.$store.dispatch(`transactionEF/${target}`, payload);
    }
  }
};
