<script>
import { RenderUtils } from "/global/utils/rendering";

export default {
  name: "ReceiptDialog",
  props: ["contents"],
  mixins: [RenderUtils],
  data() {
    return {};
  },
  computed: {
    html() {
      return `<div id="receipt">${this.contents}</div>`;
    },
  },
};
</script>

<style lang="scss" scoped>
#receipt::v-deep {
  padding: 20px;
  padding-bottom: 50px;
  background-color: white;

  .banner {
    font-family: sans-serif;
    background-color: $primary;
    color: white;
    font-weight: 600;
    padding: 0.2in;
    width: 100%;
    font-size: 1.5rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    height: calc(100% - 0.4in);
    width: 100%;
    padding: 0.2in;
  }

  .invoice-section {
    height: 30%;
    display: -webkit-box;
    padding-top: 0.5in;
    padding-bottom: 0.25in;
  }

  .invoice {
    color: #4c4b4b;
    width: 45%;
  }

  .payment-details-section {
    border-top: 2px solid #d6d6d6;
    padding-top: 0.2in;
    margin-top: 0.55in;
  }

  ul {
    list-style-type: none;
    font-size: 1.1rem;
  }

  .list-content {
    width: 95%;
    position: relative;
    padding-bottom: 8px;
  }

  .list-content-total {
    width: 95%;
    border-top: 2px solid #d6d6d6;
    position: relative;
    padding-top: 25px;
    margin-top: 20px;
    font-weight: bold;
  }

  .method-section {
    width: 100%;
    padding-left: 55%;
    font-weight: bold;
    font-size: 1.25rem;
    color: #272727;
    position: relative;
  }

  .method-desc {
    position: absolute;
    right: 0;
    font-weight: normal;
  }
}
</style>